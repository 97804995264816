import React from "react"
import Seo from "../../seo"
import BuildMaximo from "./build-maximo"

const BuildYourMaximo = () => {
  return (
    <div>
      <Seo
        title="Build your Ibm maximo apps with better building blocks"
        description="Advanced build in designer fonctionalities. Leverage modern components to enhance user experience"
      />
      <BuildMaximo />
    </div>
  )
}

export default BuildYourMaximo
