import React from "react"
import CardItem from "../../how-cards/card-item"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./index.scss"
import DescriptionBuildmaximo from "./description-build-maximo"
import YellowButton from "../../../home/buttons/yellow-button"
import { BsArrowRight } from "react-icons/bs"
const BuildMaximo = () => {
  let cardInfo = []
  let info = {}

  const data = useStaticQuery(graphql`
    {
      mdx(fileAbsolutePath: { regex: "/(buildmaximo)/" }, exports: {}) {
        exports {
          Buildmaximo {
            subTitle
            toPage
            title
            cardImage
            titleBtn
          }
        }
        frontmatter {
          subTitleDetailBuild1

          titleBottom1
          titleBottom2
          titleButton
          sizeButton
          titleDetailBuild
        }
      }
    }
  `)

  cardInfo = data.mdx.exports.Buildmaximo
  info = data.mdx.frontmatter

  return (
    <div id="BuildMaximo" className="BuildMaximo">
      <div className=" px-5 py-5">
        <h1 className="title">{info?.titleDetailBuild}</h1>
        <p className="subTitleDetail">{info?.subTitleDetailBuild1}</p>
      </div>

      <div id="howCards" className="row ">
        <div className="col-12 col-lg-6 p-0 pt-3">
          <DescriptionBuildmaximo />
        </div>

        {cardInfo?.map((item, index) => {
          return (
            <div
              className="col-12 col-lg-6 p-0"
              key={index}
              data-testid={index}
            >
              <Link to={item.toPage} className="link">
                <CardItem {...item} />
              </Link>
            </div>
          )
        })}
      </div>
      <div className="row justify-content-center  py-5 my-5">
        <span className="centeredText ">
          {info?.titleBottom1}
          <br />
          {info?.titleBottom2} {"?"}
        </span>
        <div className="btn-buildmax-bottom mt-3 py-2">
          <YellowButton
            data={info?.titleButton}
            size={info?.sizeButton}
            children={
              <BsArrowRight size={20} className="yellow-btn-arrow ms-2" />
            }
            link="/docs/Tutorials/"
          />
        </div>
      </div>
    </div>
  )
}

export default BuildMaximo
