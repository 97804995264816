import React from "react"

import "./index.scss"

const DescriptionBuildMaximo = () => {
  return (
    <div id="BuildMaximo" className=" BuildMaximo p-5">
      <div className="row">
        <div className="col-12  ">
          <p className="descriptionMain">
            <strong>Advanced build </strong> in{" "}
            <strong> designer fonctionalities </strong>, such conditional UI,
            writing function validation, provide an extreme set of features that
            adapts to your organization need.
          </p>
        </div>
      </div>
    </div>
  )
}

export default DescriptionBuildMaximo
