import React from "react"
import BuildYourMaximo from "../../components/how-it-works/build-your-maximo"

import Layout from "../../components/layout/t1/layout"

const BuildYourMaximoPage = () => {
  return (
    <Layout>
      <BuildYourMaximo />
    </Layout>
  )
}

export default BuildYourMaximoPage
